body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.newBtn{
  position: absolute;
  right: 10px;
  width: 35px;
  height: 35px;
  border: 0px;
  background-color: rgb(250, 146, 0.55);
}
.searchBtn{
  position: absolute;
  right: 60px;
  width: 35px;
  height: 35px;
  border: 0px;
  background-color: rgb(146,250, 0, 0.35);
}
.homeBtn{
  position: absolute;
  right: 120px;
  width: 35px;
  height: 35px;
  border: 0px;
  background-color: rgb(146,146,250, 0.35);
}

.editBtn{
  background-color: aquamarine;
}
.saveBtn{
  background-color: cornflowerblue;
  display: none;
}

.saveNewBtn, .searchConfirmBtn{
  background-color: cornflowerblue;
}
.cancelNewBtn, .cancelSearch, .removeBtn{
  background-color: rgba(255,0,0, 0.5);
}

.viewDiv{
  padding: 10px;
}

.editArea{
  width: 95%;
  font-family: 'Roboto';
  font-size: 1.2em;
  padding: 10px;;
  position: relative;
  margin: 10px;
  right: 10px;
  display: none;
}

.toolBtn{
  width: 35px;
  height: 35px;
  border: 0px;
  border-radius: 3px;

  &:active {
      background: rgba(250, 250, 250, 0.5);
      box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.15),
          1px 1px 15px 2px rgba(154, 137, 164, 0.1);

      text-shadow: 1px -1px 1px rgba(143, 162, 199, 0.75),
          -1px 1px 1px rgba(192, 151, 178, 0.75);

      -webkit-transition: background 0.3s color 0.2s,
          text-shadow 0.3s box-shadow 0.3s;
      -moz-transition: background 0.3s color 0.2s,
          text-shadow 0.3s box-shadow 0.3s;
      transition: background 0.3s color 0.2s, text-shadow 0.3s box-shadow 0.3s;
  }
}

.toolImg{
  position: relative;
  top: 2px;
  right: 1px;
}

.tag{
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  font-size: 0.8em;
  top: 5px;
  padding: 4px;
  background-color: rgba(125,125,125,0.2);
  opacity: 0.75;
  width: 25px;
  text-align: center;
  border-radius: 7px;
}